<template>
  <div>
    <ys-page-list
        ref="table"
        action="org/List"
        :params="params"
        :isEmptySelect="isEmptySelect"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="组织类型" prop="oType" v-if="!oType&&oType!==0">
          <Select v-model="params.oType" clearable placeholder="组织类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['组织类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="所在地区" prop="tag">
          <ysRegion v-model="params.region" @on-change="getRegion"></ysRegion>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <detail :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components.js';

export default {
  name: 'org-component',
  mixins: [pageList, components],
  props: {
    oType: {type: Number, default: null},
    IDs: {type: Array, default: () => []},
  },
  computed: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 230, title: '机构名称', key: 'iname', align: 'left',},
        {width: 100, title: '机构类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData',parentName: '组织类型'}},
        {width: 130, title: '机构简称', key: 'shortName', align: 'left'},
        {width: 200, title: '社会信用代码', key: 'societyCode', align: 'left'},
        {width: 100, title: '联系人', key: 'contacts', align: 'left'},
        {width: 150, title: '联系电话', key: 'phone', align: 'left', renderConfig: {type: 'phoneDesensitise'}},
        {width: 250, title: '所在地', key: 'address', align: 'left'},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ],
      modal: '',
      params: {
        countyCode: '',
        cityCode: '',
        provinceCode: '',
        oType: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.params.oType = this.oType;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
  }
}
</script>
